import { Stack } from "@mui/material";
import React, { createContext, useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import maxios from "../../../api";
import axios from "../../../api";
import { StatusContext } from "../../../App";
import MsgsDialog from "../Users/MsgsDialog";
import { CreateGroupButton } from "./GroupsRoot";
import TableOrders from "./TableOrders";
import TableTimeLine from "./TableTimeLine";
export const GroupAContext = createContext();

const addressOrder = [
  "TANGER",
  "FES",
  "MEKNES",
  "KENITRA",
  "RABAT",
  "MOHAMMADIA",
  "CASABLANCA",
  "BERRACHID",
  "SETTAT",
  "MARRAKESH",
  "AGADIR",
  "TAROUDANT",
];

const normalize = (str) => {
  return str
    .toUpperCase()
    .replace(/[^A-Z]/g, '') // Remove non-alphabetic characters
    .normalize('NFD') // Normalize accented characters
    .replace(/[\u0300-\u036f]/g, ''); // Remove diacritics
};

export default function GroupA({
  GroupData,
  setGroupAData,
  setCreateGroup,
  CreateGroupStatus,
  Switchgroups,
  isSwitched,
  DeleteCommands,
  setisSwitched,
}) {
  const { dispatch } = useContext(StatusContext);
  const [selected, setSelected] = React.useState([]);
  const navigate = useNavigate();

  const getAddressIndex = (address) => {
    if (!address) return addressOrder.length; // No address, place at end

    const normalizedAddress = normalize(address);

    for (let i = 0; i < addressOrder.length; i++) {
      const city = normalize(addressOrder[i]);

      // Compare first 3 letters
      const cityPrefix3 = city.substring(0, 3);
      const addressPrefix3 = normalizedAddress.substring(0, 3);
      if (addressPrefix3 === cityPrefix3) {
        return i;
      }

      // Compare first 4 letters
      const cityPrefix4 = city.substring(0, 4);
      const addressPrefix4 = normalizedAddress.substring(0, 4);
      if (addressPrefix4 === cityPrefix4) {
        return i;
      }
    }

    return addressOrder.length; // Not found, place at end
  };

  const sortGroupData = (data) => {
    return data.sort((a, b) => {
      const indexA = getAddressIndex(a.recipient.address);
      const indexB = getAddressIndex(b.recipient.address);
      return indexA - indexB;
    });
  };

  const fetchGroupA = async () => {
    try {
      dispatch({ type: "showLoading", payload: true });
      const res = await maxios.get(`/api/v1/trips?name=A`);
      if (res.data.count > 0) {
        const cmds = await maxios.get(
          `/api/v1/commands?tripGroup=A&&commandMode=tracking&&finish=false`
        );
        const sortedData = sortGroupData(cmds.data.data);
        console.log("sortedData", sortedData);
        setGroupAData([...sortedData]);
        dispatch({ type: "showLoading", payload: false });
        dispatch({
          type: "showSuccess",
          payload: "Commands to Morocco Fetched",
        });
      } else {
        dispatch({ type: "showLoading", payload: false });
        dispatch({ type: "showSuccess", payload: "Create Group" });
        setCreateGroup(true);
      }
    } catch (error) {
      dispatch({ type: "showLoading", payload: false });
      dispatch({ type: "showError", payload: "Server Error Please Reload" });
    }
  };

  const openChat = () => {
    navigate("sendMessages");
  };

  React.useEffect(() => {
    const fetch = async () => {
      await fetchGroupA();
    };
    fetch();
  }, [CreateGroupStatus, isSwitched]);

  console.log("GroupA", GroupData);
  return (
    <GroupAContext.Provider
      value={{ GroupData, setGroupAData, setisSwitched, isSwitched }}
    >
      {CreateGroupStatus === true ? (
        <CreateGroupButton name="A" setCreateGroup={setCreateGroup} />
      ) : (
        <>
          <Stack direction="column" spacing={3}>
            <TableTimeLine Context={GroupAContext} />
            <TableOrders
              Context={GroupAContext}
              Data={GroupData}
              Switchgroups={Switchgroups}
              DeleteCommands={DeleteCommands}
              label={{ label: "Tracking", type: "tracking" }}
              gname={"A"}
              toname={"B"}
              selected={selected}
              setSelected={setSelected}
              openChat={openChat}
            />
          </Stack>
          <Routes>
            <Route
              path="/sendMessages"
              element={
                <MsgsDialog selected={selected} userStates={GroupData} />
              }
            />
          </Routes>
        </>
      )}
    </GroupAContext.Provider>
  );
}
